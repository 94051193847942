// Module
var code = `<template>
  <require from="./generate_qr_code.scss"></require>
  <require from="../../../components/card-banka-detailled/card-banka-detailled"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>  
  <require from="../../../components/number-format"></require>
  <require from="../../../components/header-titles/header-title-qr/header-title-qr"></require>
  <section id="generate_qr_code" class="flex-column">
    <header-title-qr title.bind="pageTitle"></header-title-qr>
    <div class="flex-grow"></div>
    </div>
    <div class="flex-row form_container" class.bind="shaking ? 'shake' : ''">
      <form id="payment_price" class="flex-row"
        class.bind="(isSelectingRecipient || isSelectingSender) ? 'selecting' : ''"
        submit.delegate="gotToQrCodeReceive()">
        <input disabled.bind="locked" id="qrCodeAmount" type="text"
          value.bind="formattedAmount"
          placeholder="0.00" required="required"
          class.bind="isAmountError ? 'error' : ''"
          input.trigger="onAmountInput(\$event)" 
          autofocus/>
        <p class="currency">\${state.wallets[state.selectedAccountIndex].currency}</p>
      </form>
    </div>
    <div id="communication_type_switch" class="flex-column form_container"
      class.bind="(isSelectingRecipient || isSelectingSender) ? 'selecting' : ''">
      <form submit.delegate="gotToQrCodeReceive()" id="payment_reference" class="flex-row">
        <input id="qrCodeReference" type="text" value.bind="qrCodeReference" placeholder="random comment"/>
      </form>
    </div>
    <div class="flex-grow"></div>
    <div class="confirm">
      <button class="btn btn-primary" click.delegate="goToQrCodeReceive()" i18n="home.next"
        form="payment_price && payment_reference" disabled.bind="!(qrCodeAmount > 0) || isLoading">Suivant</button>
    </div>
    <div class="spacer-16"></div>
  </section>
</template>
`;
// Exports
export default code;